<template>
  <!-- 医院端 订单管理 -- 我购买的课程 -- 课程详情  /web/coursesIboughtInfo -->
  <div class="pageContol formCom coursePage">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom: 20px">课程基本信息</h3>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
              :inline="true"
            >
              <el-form-item label="课程名称：" class="form-inline">
                <p>{{ ruleForm.courseName }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.trainTypeNamePath"
                label="培训类型："
                class="form-inline"
              >
                <p>{{ ruleForm.trainTypeNamePath || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.industryNamePath"
                label="行业类型："
                class="form-inline"
              >
                <p>{{ ruleForm.industryNamePath || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.postName"
                label="岗位类型："
                class="form-inline"
              >
                <p>{{ ruleForm.postName || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.occupationNamePath"
                label="职业/工种："
                class="form-inline"
              >
                <p>{{ ruleForm.occupationNamePath || "" }}</p>
              </el-form-item>
              <el-form-item
                v-show="ruleForm.trainLevelName"
                label="培训等级："
                class="form-inline"
              >
                <p>{{ ruleForm.trainLevelName || "" }}</p>
              </el-form-item>
            </el-form>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-width="10rem"
            >
              <el-form-item label="课程详情：" prop="context" class="form-item">
                <div
                  v-html="ruleForm.context"
                  ref="editor"
                  style="width: 100%; position: relative; z-index: 2"
                />
              </el-form-item>
              <el-form-item label="课程图片：" prop="thumbnail">
                <div
                  class="img-el-upload"
                  style="width: 256px; padding-top: 10px"
                >
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                  ></el-image>
                </div>
              </el-form-item>
              <el-form-item label="讲师备注：" prop="lecturer">
                <p>{{ ruleForm.lecturer || "无" }}</p>
              </el-form-item>
            </el-form>
            <h3 style="padding-bottom: 20px">课程章节</h3>
            <div style="display: flex">
              <div style="flex: 1">
                <section class="chapterl-trees">
                  <el-tree
                    :data="data"
                    node-key="id"
                    default-expand-all
                    v-if="data"
                  >
                    <div
                      class="custom-tree-node df"
                      style="align-items: center"
                      slot-scope="{ node, data }"
                    >
                      <p v-if="data.level === 1">
                        <!-- <span style="margin-right:10px;">一级</span> -->
                        <span>{{ node.label }}</span>
                      </p>
                      <template v-else-if="data.level === 2">
                        <!-- <span v-if="styleType == '1'" style="margin-right:10px;">二级</span> -->
                        <!-- <span v-else style="margin-right:10px;">一级</span> -->
                        <span
                          v-if="data.list.length > 0"
                          style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          "
                        >
                          <i
                            class="courseIcon"
                            v-if="data.list[0].kpointId"
                          ></i>
                          <i
                            class="paperIcon"
                            v-else-if="data.list[0].paperId"
                          ></i>
                        </span>
                        <span
                          v-else
                          style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                          "
                        >
                          <i class="nullIcon"></i>
                        </span>
                        <span class="ellipsis">{{ data.label }} </span>
                        <span
                          class="trees-btns"
                          style="margin-left: 57px"
                          :canDarg="data.list.length"
                        >
                          <el-button
                            class="bgc-bv showBtn"
                            size="mini"
                            v-if="data.trySee"
                            @click.stop="() => showVideo(data)"
                          >
                            试看
                          </el-button>
                          <template v-if="data.list.length">
                            <span
                              class="draggable-item handle"
                              v-for="element in data.list"
                              :key="element.name"
                            ></span>
                          </template>
                        </span>
                      </template>
                      <!-- <template v-else>
                        <p class="df" style="align-items: center">
                          <i
                            class="el-icon-tickets"
                            style="margin-right: 5px"
                          ></i>
                          <span class="ellipsis" style="margin-right: 23px">
                            {{ node.label }}
                          </span>
                          <span class="trees-btns">
                            <el-button
                              class="bgc-bv showBtn"
                              size="mini"
                              v-if="data.trySee"
                              @click.stop="() => showPaper(data)"
                            >
                              试看
                            </el-button>
                          </span>
                        </p>
                      </template> -->
                    </div>
                  </el-tree>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="butbox">
          <el-button class="bgc-bv" @click="$router.back()">返回</el-button>
          <!-- <el-button
            class="bgc-bv"
            @click="salesState"
            :disabled="addState == 0 && notState == 0 ? false : true"
            >加入我的课程</el-button
          > -->
        </div>
      </div>
    </div>

    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-if="videoType"
        ref="player"
      />
      <player_1
        :videoId="videoId"
        :courseId="courseId"
        :kpointId="kpointId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-else
        ref="player"
      />
    </el-dialog>

    <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
import PaperShow from "@/views/resourse/popup/PaperShow";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../utils/swigger";
export default {
  name: "coursePages",
  components: {
    PaperShow,
    player_1,
    player_2,
  },
  data() {
    return {
      addState: this.$route.query.addState || "",
      notState: this.$route.query.notState || "",
      detailResource: {},
      courseId: this.$route.query.courseId || "",
      projectCourseId: this.$route.query.projectCourseId || "",
      styleType: "1",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      ruleForm: {
        agreement: false,
        resource: "",
        thumbnailId: "",
        thumbnail: "",
        context: "", // 富文本
        salesState: "20",
        trainTypeName: "",
        lecturer: "",
      },
      // tree
      draggable: true,
      treeLable: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      styleStu: false,
      videoType: false, // true-试看；false-预览
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getDetail();
    },
    getDetail() {
      console.log(this.courseId);
      this.$post("/run/projectCourse/display", {
        courseId: this.courseId,
        projectCourseId: this.projectCourseId,
      }).then((result) => {
        console.log(result);
        if (result.status === "0") {
          this.creatData(result.data);
        }
      });
    },
    creatData(data = {}) {
      let ret = {
        agreement: false,
        courseName: data.courseName,
        context: data.context,
        thumbnailId: data.logo,
        salesState: data.salesState,
        thumbnail: data.logoURL,
        lecturer: data.lecturer,
        resource: "",
        trainTypeNamePath: data.trainTypeNamePath,
        postName: data.postName,
        industryNamePath: data.industryNamePath,
        occupationNamePath: data.occupationNamePath,
        trainLevelName: data.trainLevelName,
      };
      //
      this.styleType = data.chapterSchema ? "1" : "2";
      this.ruleForm = ret;
      //
      let list = [];
      if (data.chapterSchema) {
        data.nodes.forEach((element) => {
          let nodeList = [];
          list.push({
            label: element.catalogName,
            id: element.courseKpointId,
            children: nodeList,
            level: 1,
            trySee: element.trySee,
          });
          (element.child || []).forEach((obj) => {
            nodeList.push(this.section2Item(obj));
          });
        });
      } else {
        data.nodes.forEach((element) => {
          list.push(this.section2Item(element));
        });
      }
      this.data = list;
      console.log("列表数据: ", list);
    },
    section2Item(obj) {
      let children = [];
      if (obj.paperId) {
        children = [
          {
            paperId: obj.paperId,
            label: obj.paperName,
            level: 3,
            trySee: obj.trySee,
          },
        ];
      }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointVideoId: obj.kpointVideoId,
          kpointName: obj.kpointName,
          kpointDuration: obj.kpointDuration,
          kpointId: obj.kpointId,
          kpointLessonNum: obj.kpointLessonNum,
          kpointLogo: obj.kpointLogo,
          kpointType: obj.kpointType,
          qualificationId: obj.qualificationId,
          kpointDeleted: obj.kpointDeleted, // 课件是否删除了
          progress: 100,
          kpointSource: obj.kpointSource,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          progress: 100,
          buyId: obj.buyId || "",
          paperId: obj.paperId,
          paperName: obj.paperName,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          label: obj.catalogName,
          id: obj.courseKpointId,
          kpointId: obj.kpointId,
          level: 2,
          list: listArr,
          children: children,
          trySee: obj.trySee,
        };
      } else {
        return {
          label: obj.catalogName,
          id: obj.courseKpointId,
          kpointId: obj.kpointId,
          level: 2,
          list: listArr,
          children: [],
          paperId: obj.paperId,
          paperName: obj.paperName,
          trySee: obj.trySee,
        };
      }
    },
    // 加入
    salesState() {
      this.$post("/biz/course/insertPlatformCourseToMine", {
        courseId: this.courseId,
      })
        .then((res) => {
          if (res.status == 0) {
            this.$message({
              type: "success",
              message: "已成功加入我的课程",
            });
            this.$router.push({
              name: "courseList",
              params: {
                refresh: true,
                refName: "first",
              },
            });
          }
        })
        .catch(() => {
          return;
        });
    },
    // 预览
    showVideo(data) {
      console.log(data);
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.list[0].kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.list[0].kpointVideoId;
            _this.videoType = result.data.trySee;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.label;
            _this.courseId = _this.courseId;
            _this.kpointId = data.kpointId;
            _this.kpointSource = data.list[0].kpointSource;
            _this.dialogVisible1 = true;
          });
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        this.showPaper(data);
      }
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    handleClose() {
      this.dialogVisible1 = false;
      this.status = 3;
      this.$refs.player.dd();
      distorySwigger();
    },
    // // 预览
    // showPaper(data) {
    //   this.$router.push({
    //     path: "/web/testPaperEdit", 
    //     query: {
    //       stu: false,
    //       paperId: data.paperId,
    //       btn:true
    //     }
    //   });
    //   // this.$store.dispatch("handleVideoUrl", data.list[0].kpointVideoId);
    // }
  },
  watch: {
    $route: {
      handler: function (n, o) {
        if (n.path == "/web/courseList" && o.path == "/web/coursePages") {
          this.$refs.first.getData();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less">
.form-inline {
  width: 45%;
  p {
    width: 15rem;
  }
}

.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }

  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > .handle {
        // color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.butbox {
  display: flex;
  justify-content: center;
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
</style>
<style lang="less">
.coursePage {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 20rem;
    font-size: 14px;
  }
  .courseIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    background: url("../../assets/courseIcon.png") no-repeat;
    background-size: cover;
  }
  .paperIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
    background: url("../../assets/paperIcon.png") no-repeat;
    background-size: cover;
  }
  .nullIcon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
    display: inline-block;
  }
}
</style>

